import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {EFormJobTimeRule, EFormJobType, EJobPriority} from 'src/app/models'
import {v4 as uuidv4} from 'uuid'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface IJobState {
  job_form?: {
    // informasi job
    name?: string
    time_rule?: keyof typeof EFormJobTimeRule
    start_date?: string
    end_date?: string
    start_time?: string
    end_time?: string
    priority?: keyof typeof EJobPriority
    wo_number?: string
    description?: string
    address?: string
    location?: string
    location_name?: string
    job_type?: string

    // informasi agen
    pic_agent?: string
    pic_agent_label?: string
    support_agents?: {
      label?: string
      value?: string
    }[]
    has_attendance?: boolean
    radius?: string
    is_selfie?: boolean

    // pemeriksa job
    approval?: boolean
    approval_by?: string
    approval_by_label?: string
  }
  forms?: {
    id?: string
    name?: string
    type?: keyof typeof EFormJobType
    description?: string
    data?: {
      name?: string
      id?: string
    }[]
  }[]
  step?: number
  errors?: any
  touched?: any
}

namespace JobRedux {
  export const actionTypes = {
    SetJobForm: '[SetJobForm] Action',
    SetTaskForm: '[SetTaskForm] Action',
    SetStepForm: '[SetStepForm] Action',
    SetResetForm: '[SetResetForm] Action',
    SetError: '[SetError] Action',
  }

  export const initialJobState: IJobState = {
    job_form: {
      // informasi job
      name: '',
      time_rule: 'FIXED',
      start_date: '',
      end_date: '',
      start_time: '',
      end_time: '',
      priority: undefined,
      wo_number: '',
      description: '',
      address: '',
      location: '',
      job_type: 'SINGLE',

      // informasi agen
      pic_agent: '',
      pic_agent_label: '',
      support_agents: [],
      has_attendance: false,
      radius: '',
      is_selfie: false,

      // pemeriksa job
      approval: false,
      approval_by: '',
      approval_by_label: '',
    },
    forms: [
      {
        id: uuidv4(),
        name: '',
        type: undefined,
        description: '',
        data: [
          {
            id: uuidv4(),
            name: '',
          },
        ],
      },
    ],
    step: 1,
    errors: {},
    touched: {},
  }

  export const reducer = () =>
    persistReducer<IJobState, ActionWithPayload<IJobState>>(
      {
        storage,
        key: 'JOB-FORM',
        whitelist: [''],
      },
      (state: IJobState = initialJobState, action = {type: ''}) => {
        switch (action.type) {
          case actionTypes.SetJobForm: {
            const form = action.payload?.job_form

            return {...state, job_form: form}
          }

          case actionTypes.SetTaskForm: {
            const form = action.payload?.forms

            return {...state, forms: form}
          }

          case actionTypes.SetStepForm: {
            const step = action.payload?.step

            return {...state, step}
          }

          case actionTypes.SetError: {
            const errors = action.payload?.errors
            const touched = action.payload?.touched
            return {...state, errors, touched}
          }

          case actionTypes.SetResetForm: {
            return initialJobState
          }

          default:
            return state
        }
      }
    )

  export const actions = {
    setJobForm: (data: IJobState) => ({
      type: actionTypes.SetJobForm,
      payload: data,
    }),
    setTaskForm: (data: IJobState) => ({
      type: actionTypes.SetTaskForm,
      payload: data,
    }),
    setStepForm: (step: number) => ({
      type: actionTypes.SetStepForm,
      payload: {step},
    }),
    setResetForm: () => ({
      type: actionTypes.SetResetForm,
    }),
    setErrors: (errors: any, touched: any) => ({
      type: actionTypes.SetError,
      payload: {errors, touched},
    }),
  }
}

export default JobRedux
