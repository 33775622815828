import {ScreenProp} from 'src/app/models'
import {KalenderJobScreen} from '../page/Kalender/Screen'

type TJobTugasKeys = keyof typeof KalenderJobScreen

type TKeys = TJobTugasKeys

const KalenderScreens: Record<TKeys, ScreenProp> = {
  ...KalenderJobScreen,
}

export default KalenderScreens
