import axios from 'axios'
import {
  BaseResponse,
  ICalendarAgenRequest,
  ICalendarAgenResponse,
  ICalendarDotMonthRequest,
  ICalendarDotMonthResponse,
  ICalendarJobRequest,
  ICalendarJobResponse,
  Pagination,
  Response,
} from 'src/app/models'

export const CALENDAR_AGEN = '/api/v1/admins/agents/by-job'
export const CALENDAR_JOB = '/api/v1/admins/calendar-job'
export const CALENDAR_MONTHLY = '/api/v1/admins/tasks/monthly-job-date'

export const getCalenderAgenList = (data?: ICalendarAgenRequest) => {
  return axios.post<BaseResponse<Pagination<ICalendarAgenResponse[]>>>(CALENDAR_AGEN, data)
}
export const getCalenderJobList = (
  data?: ICalendarJobRequest,
  params?: {page?: number; size?: number}
) => {
  return axios.post<BaseResponse<Pagination<ICalendarJobResponse[]>>>(CALENDAR_JOB, data, {params})
}

export const getCalendarMonthDotList = (params?: ICalendarDotMonthRequest) => {
  return axios.get<BaseResponse<Response<ICalendarDotMonthResponse>>>(CALENDAR_MONTHLY, {params})
}
