import {ScreenProp} from 'src/app/models'

type TKeys = 'KALENDER' | 'KALENDER_LIST'

export const KalenderJobScreen: Record<TKeys, ScreenProp> = {
  KALENDER: {
    PATH: '/calendar',
    TITLE: 'Kalender Job',
    TITLE_ALT: 'Kalender Job',
  },
  KALENDER_LIST: {
    PATH: '/calendar/list',
    TITLE: 'Kalender Job',
    TITLE_ALT: 'Kalender Job',
  },
}
