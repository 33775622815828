import axios from 'axios'
import {
  BaseResponse,
  EGlobalSlug,
  IGlobal,
  IGlobalQuery,
  Pagination,
  TGlobalSlug,
} from 'src/app/models'

export const GLOBAL = '/api/v1/admins/settings/global-param'

export const LIST_GLOBAL_KEY = 'list-global-key'

export const getListGlobal = (slug: TGlobalSlug, params?: IGlobalQuery) => {
  return axios.get<BaseResponse<Pagination<IGlobal[]>>>(`${GLOBAL}/${EGlobalSlug[slug]}`, {
    params,
  })
}

export const globalSlugs: TGlobalSlug[] = [
  'GENDER',
  'REGEX',
  'TASK_PRIORITY',
  'TASK_RECURRING_FREQUENCY',
  'TASK_REMINDER',
  'TASK_STATUS',
  'USER_LEVEL',
  'USER_STATUS',
  'CLIENT_STATUS',
]

export const fetchGlobalData = async () => {
  try {
    const requests = globalSlugs.map((slug) => getListGlobal(slug))

    const results = await Promise.all(requests)

    let store: any[] = []

    results.forEach((response, index) => {
      const data = {
        [globalSlugs[index].toLowerCase()]: response.data.response_output?.list?.content,
      }

      store.push(data)
    })

    return store
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message)
    } else {
      console.error('Error fetching global data:', error)
    }
  }
}

export const searchGlobalByKey = (data: any, key: any) => {
  const foundObject = data?.find((obj: any) => obj[key])
  return foundObject ? foundObject[key] : null
}
