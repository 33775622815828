import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {useMasterLayout} from 'src/app/context/MasterContext'
import {IProfile} from 'src/app/models'
import {RootState} from 'src/app/store/RootReducer'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers.utils'
import {cn} from 'src/app/utils/cn.utils'

const AsideLogo: FC = () => {
  const user = useSelector<RootState>(({auth}) => auth?.user, shallowEqual) as IProfile
  const {minimize} = useMasterLayout()

  return (
    <div className={cn('flex items-center h-[80px] pl-5 py-4')}>
      <Link
        to={user?.level?.type === 'L0' ? '/administration/setup-organization' : '/dashboard'}
        className={cn('relative flex items-center')}
      >
        {minimize ? (
          <>
            <img
              src={toAbsoluteUrl('/logo_optera_white_small.svg')}
              alt='optera_logo'
              className='w-8 h-8'
            />
          </>
        ) : (
          <img
            src={toAbsoluteUrl('/logo_optera_white.svg')}
            alt='optera_logo'
            className='h-[45px]'
          />
        )}
      </Link>
    </div>
  )
}

export default AsideLogo
