import {Suspense, lazy} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../components/Layout/FallbackView'
import {IProfile} from '../models'
import AdministrationScreens from '../modules/administration/routes/Screens'
import LogActivityScreens from '../modules/aktivitas-log/routes/Screen'
import DashboardScreens from '../modules/dashboard/routes/Screen'
import Error404 from '../modules/error/error404'
import JobScreens from '../modules/job-tugas/routes/Screen'
import KalenderScreens from '../modules/kalender-job/routes/Screen'
import OnboardingAgent from '../modules/onboarding/page/OnboardingAgent'
import OnboardingPage from '../modules/onboarding/page/OnboardingPage'
import OnboardingScreens from '../modules/onboarding/routes/Screen'
import ProfileScreens from '../modules/profile/routes/Screens'
import SettingScreens from '../modules/setting/routes/Screen'
import UserScreens from '../modules/user/routes/Screen'
import {RootState} from '../store/RootReducer'

export function PrivateRoutes() {
  const user = useSelector<RootState>(({auth}) => auth?.user, shallowEqual) as IProfile

  const ProfilePage = lazy(() => import('../modules/profile/routes'))
  const DashboardPage = lazy(() => import('../modules/dashboard/routes'))
  const KalenderPage = lazy(() => import('../modules/kalender-job/routes'))
  const UserPage = lazy(() => import('../modules/user/routes'))
  const JobPage = lazy(() => import('../modules/job-tugas/routes'))
  const LogActivityPage = lazy(() => import('../modules/aktivitas-log/routes'))
  const SettingPage = lazy(() => import('../modules/setting/routes'))
  const AdministrationPage = lazy(() => import('../modules/administration/routes'))

  return (
    <>
      <Suspense fallback={<FallbackView />}>
        <Switch>
          <Route path={ProfileScreens.PROFILE.PATH} component={ProfilePage} />
          <Route path={DashboardScreens.DASHBOARD.PATH} component={DashboardPage} />
          <Route path={KalenderScreens.KALENDER.PATH} component={KalenderPage} />
          <Route path={UserScreens.USER.PATH} component={UserPage} />
          <Route path={JobScreens.JOB.PATH} component={JobPage} />
          <Route path={LogActivityScreens.LOG_ACTIVITY.PATH} component={LogActivityPage} />
          <Route path={SettingScreens.SETTING.PATH} component={SettingPage} />

          {/* Administration hanya sementara */}
          <Route path={AdministrationScreens.ADMINISTRATION.PATH} component={AdministrationPage} />

          {/* KE HALAMAN ONBOARDING (AUTO LOGOUT) */}
          <Route path={OnboardingScreens.ONBOARDING.PATH} component={OnboardingPage} />
          <Route path={OnboardingScreens.ONBOARDING_AGENT.PATH} component={OnboardingAgent} />

          <Route path='/error/404' component={Error404} />

          {user?.level?.type === 'L0' ? (
            <>
              <Redirect from='/auth' to='/administration/setup-organization' />
              <Redirect exact from='/' to='/administration/setup-organization' />
            </>
          ) : (
            <>
              <Redirect from='/auth' to='/dashboard' />
              <Redirect exact from='/' to='/dashboard' />
            </>
          )}
          <Redirect to='/error/404' />
        </Switch>
      </Suspense>
    </>
  )
}
