import {FC} from 'react'
import {cn} from 'src/app/utils/cn.utils'
import {FormLabelProps} from './Label.types'

export const FormLabel: FC<FormLabelProps> = ({
  children,
  required,
  description,
  className,
  textClassName,
  appendTitle,
}) => {
  return (
    <div className={cn('flex flex-col', className)} data-testid='form-label-container'>
      <label
        className={cn(
          'flex-grow font-medium text-fs-9 text-neutral-500 flex gap-1 items-center',
          textClassName
        )}
      >
        {children}
        {required && <span className='text-danger-500'>*</span>}
        {appendTitle && <>{appendTitle}</>}
      </label>

      {description && (
        <div className='flex items-center mt-1'>
          <label className='text-fs-10 text-neutral-400'>{description}</label>
        </div>
      )}
    </div>
  )
}
