import {FC, useEffect, useState} from 'react'
import Select, {
  ClassNamesConfig,
  components,
  ControlProps,
  GroupBase,
  SelectComponentsConfig,
} from 'react-select'
import {FormSelectProps, SelectOption} from 'src/app/components/Libs'
import {cn} from 'src/app/utils/cn.utils'

interface ISelectButton extends FormSelectProps {
  hardClassName?: any
}

const Control = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  children,
  isFocused,
  isDisabled,
  ...rest
}: ControlProps<Option, IsMulti, Group>) => (
  <components.Control
    className={cn('shadow-none bg-white h-[40px]', {
      focus: isFocused,
      disabled: isDisabled,
    })}
    isFocused
    isDisabled
    {...rest}
  >
    {children}
  </components.Control>
)

const IndicatorSeparator = () => null

const NoOptionsMessage = (props: any) => {
  return <components.NoOptionsMessage {...props}>no</components.NoOptionsMessage>
}

const CustomReactSelect = {
  Control,
  IndicatorSeparator,
  NoOptionsMessage,
}

export const templateName = ({disabled}: any): ClassNamesConfig<any, false, any> => ({
  placeholder: () => 'text-neutral-700 font-medium',
  control: () =>
    `text-fs-10 rounded-lg font-medium text-neutral-100 border-neutral-300 ${
      disabled && 'bg-neutral-200'
    }`,
  valueContainer: (state) => `min-h-0 pl-4 py-0 pr-0 h-[40px]`,
  input: () => 'm-0 p-0 font-medium',
  dropdownIndicator: (state) =>
    `transition-transform duration-150 text-neutral-700 ${
      state.selectProps.menuIsOpen ? 'rotate-180' : 'rotate-0'
    }`,
  indicatorsContainer: () => 'max-h-[40px] px-0',
  option: () =>
    'text-fs-10 rounded px-2 py-3 cursor-pointer text-black hover:bg-neutral-100 hover:text-neutral-900',
  menuList: () => 'border-none max-h-[300px] whitespace-nowrap',
  menu: () => 'shadow-none drop-shadow-[0_0_50px_rgba(33,37,41,0.13)]',
})

export const SelectButton: FC<ISelectButton> = ({
  defaultValue,
  className,
  hardClassName,
  changedValue,
  disabled,
  selectedValue,
  options,
  placeholder = 'Select',
  isLoading,
}) => {
  const [val, setVal] = useState<SelectOption | SelectOption[] | ''>('')

  const onChange = (v: SelectOption) => {
    if (changedValue) {
      changedValue(v)
      setVal(v)
    }
  }

  useEffect(() => {
    if (defaultValue) {
      setVal({value: defaultValue?.value, label: defaultValue?.label})
    }
  }, [defaultValue])

  useEffect(() => {
    if (val === undefined) {
      setVal({value: '', label: ''})
    }
  }, [val])

  useEffect(() => {
    if (selectedValue === '' || selectedValue === undefined) {
      setVal('')
    } else {
      const payload = options?.find((pay) => pay.value === selectedValue)
      setVal({value: selectedValue, label: payload?.label})
    }
  }, [selectedValue, options])

  return (
    <div className={className}>
      <Select
        data-testid='formselect-test-value'
        components={CustomReactSelect as SelectComponentsConfig<any, false, any>}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={selectedValue}
        value={val}
        isDisabled={disabled}
        isSearchable={false}
        styles={{
          option: (base, state) => ({
            ...base,
            backgroundColor: '',
          }),
          control: (base, state) => ({
            ...base,
            minHeight: '30px',
            height: '30px',
          }),
        }}
        classNames={hardClassName ?? templateName({disabled})}
        menuPortalTarget={document.querySelector('body')}
        isLoading={isLoading}
      />
    </div>
  )
}
