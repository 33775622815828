import axios from 'axios'
import {BaseResponse, IDropdown, IDropdownQuery, Pagination} from 'src/app/models'

type IDropdownResponse = BaseResponse<Pagination<IDropdown[]>>

export const DROPDOWN = '/api/v1/admins/dropdown'

export const getDropdownExportType = (params?: IDropdownQuery) => {
  return axios.get<IDropdownResponse>(`${DROPDOWN}/export/export_type`, {params})
}

export const getDropdownExportModule = (params?: IDropdownQuery) => {
  return axios.get<IDropdownResponse>(`${DROPDOWN}/export/export_module`, {params})
}

export const getDropdownDivision = (params?: IDropdownQuery) => {
  return axios.get<IDropdownResponse>(`${DROPDOWN}/division`, {params})
}

export const getDropdownAdmin = (params?: IDropdownQuery) => {
  return axios.get<IDropdownResponse>(`${DROPDOWN}/users/admins`, {params})
}

export const getDropdownAgent = (params?: IDropdownQuery) => {
  return axios.get<IDropdownResponse>(`${DROPDOWN}/users/agents`, {params})
}

export const getDropdownLocation = (params?: IDropdownQuery) => {
  return axios.get<IDropdownResponse>(`${DROPDOWN}/locations`, {params})
}

export const getDropdownJob = (params?: IDropdownQuery) => {
  return axios.get<IDropdownResponse>(`${DROPDOWN}/tasks`, {params})
}
