import {FC} from 'react'
import {Tooltip} from 'react-tooltip'

const TooltipView: FC = () => {
  return (
    <>
      <Tooltip id='view-tooltip' className='tooltip-custom'>
        Detail
      </Tooltip>
      <Tooltip id='edit-tooltip' className='tooltip-custom'>
        Edit
      </Tooltip>
      <Tooltip id='delete-tooltip' className='tooltip-custom'>
        Hapus
      </Tooltip>
      <Tooltip id='activate-tooltip' className='tooltip-custom'>
        Aktivasi
      </Tooltip>
      <Tooltip id='deactivate-tooltip' className='tooltip-custom'>
        Deaktivasi
      </Tooltip>
      <Tooltip id='send-tooltip' className='tooltip-custom'>
        Send
      </Tooltip>
      <Tooltip id='duplicate-tooltip' className='tooltip-custom'>
        Duplicate
      </Tooltip>
      <Tooltip id='reassign-tooltip' className='tooltip-custom'>
        Reassign
      </Tooltip>
      <Tooltip id='reschedule-tooltip' className='tooltip-custom'>
        Reschedule
      </Tooltip>
      <Tooltip id='refresh-tooltip' className='tooltip-custom'>
        Refresh
      </Tooltip>
      <Tooltip id='long-answer-tooltip' className='tooltip-custom'>
        The question form will be textarea
      </Tooltip>
      <Tooltip id='hide-question-tooltip' className='tooltip-custom'>
        Hide question
      </Tooltip>

      <Tooltip id='task-attendance-tooltip' className='tooltip-custom'>
        The attendance system requires agents to take
        <br /> attendance when starting or ending work.
      </Tooltip>
      <Tooltip id='task-selfie-tooltip' className='tooltip-custom'>
        Agent must take a selfie <br /> during attendance.
      </Tooltip>
      <Tooltip id='task-clockout-tooltip' className='tooltip-custom'>
        Agent must perform the attendance
        <br /> process before completing work.
      </Tooltip>
      <Tooltip id='client-account-quota' className='tooltip-client-management'>
        <span className={'text-xs'}>
          Total user terdaftar di sistem / Total paket client di OpteraOne
          <br /> Termasuk admin & agen
        </span>
      </Tooltip>
      <Tooltip id='client-account-limit-recomendation' className='tooltip-client-management'>
        <span className={'font-semibold text-xs'}>Kuota hampir mencapai batas maksimum</span>
        <br />
        <span className={'text-xs'}>
          Pertimbangkan untuk follow-up client,<br/>
          memastikan kebutuhan mereka terpenuhi & <br/>
          meningkatkan pelayanan.
        </span>
      </Tooltip>
      <Tooltip id='client-account-limit-edit' className='tooltip-client-management'>
        <span className={'text-xs'}>Pastikan untuk menghubungi client terlebih dahulu sebelum menurunkan kuota<br/>
        agar sesuai dengan paket yang dibutuhkan.</span>
      </Tooltip>
    </>
  )
}

export default TooltipView
