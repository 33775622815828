import {FC, useEffect} from 'react'
import InlineSVG from 'react-inlinesvg'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import OnboardingRedux from 'src/app/store/Onboarding/OnboardingRedux'
import {RootState} from 'src/app/store/RootReducer'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers.utils'
import TopLayout from '../components/Layout/TopLayout'

const OnboardingAgentSuccess: FC = () => {
  const dispatch = useDispatch()

  const onboardingRedux = useSelector<RootState>(
    ({onboarding}) => onboarding,
    shallowEqual
  ) as OnboardingRedux.IOnboardingState

  useEffect(() => {
    dispatch(OnboardingRedux.actions.setStep(1))
    dispatch(OnboardingRedux.actions.setEmpty())
    //eslint-disable-next-line
  }, [])

  return (
    <div className='w-full min-h-screen' data-testid='onboarding-agent-success'>
      <TopLayout state={onboardingRedux}>
        <div className='flex flex-col items-center justify-center w-full h-full px-4 bg-neutral-25 lg:px-0'>
          <InlineSVG src={toAbsoluteUrl('/media/image/image_success_onboarding.svg')} />
          <div className='mt-6 font-medium text-center text-neutral-900 text-fs-6 lg:text-fs-4 max-w-[522px]'>
            Selamat Anda telah berhasil menyelesaikan proses onboarding
          </div>
          <div className='mt-2 text-center text-neutral-600 text-fs-9 lg:text-fs-7 max-w-[522px]'>
            Silakan buka aplikasi OpteraOne, dan masuk menggunakan nomor telepon & PIN Anda. Jika
            Anda belum menginstall aplikasi, harap download dan install di bawah ini!
          </div>
          <div className='mt-6'>
            <a
              href='https://play.google.com/store/apps/details?id=com.opteraone'
              target='_blank'
              rel='noreferrer'
            >
              <img alt='playstore' src={toAbsoluteUrl('/img/logo_playstore_ina.png')} />
            </a>
          </div>
        </div>
      </TopLayout>
    </div>
  )
}

export default OnboardingAgentSuccess
