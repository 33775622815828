import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {v4 as uuidv4} from 'uuid'

namespace OnboardingRedux {
  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export type TProfileState = {
    first_name?: string
    last_name?: string
    mobile_number?: string
    company_name?: string
    email?: string
    level?: 'L1' | 'L2' | 'L3'
  }
  export type TLanguageState = 'en' | 'id'
  export type TAdminDataState = {
    first_name?: string
    last_name?: string
    mobile_number?: string
    email?: string
    password?: string
    password_confirm?: string
  }
  export type TUserState = {
    id?: string
    level?: string
    level_name?: string
    first_name?: string
    last_name?: string
    email?: string
    mobile_number?: string
  }
  export type TAgentState = {
    first_name?: string
    last_name?: string
    mobile_number?: string
    email?: string
  }
  export type TTaskState = {
    name?: string
    forms?: {
      id?: string
      name?: string
      type?: string
      description?: string
      data?: {
        id?: string
        name?: string
      }[]
    }[]
  }

  export type TErrorsState = {
    admin_data?: TAdminDataState
    users?: TUserState
    agent?: TAgentState
    task?: TTaskState
  }

  export interface IOnboardingState {
    profile?: TProfileState
    step?: number
    language?: TLanguageState
    admin_data?: TAdminDataState
    users?: TUserState[]
    agent?: TAgentState
    task?: TTaskState
    errors?: TErrorsState
  }

  export const initialOnboardingState: IOnboardingState = {
    profile: {
      first_name: '',
      last_name: '',
      mobile_number: '',
      company_name: '',
      email: '',
      level: undefined,
    },
    step: 1,
    language: 'id',
    admin_data: {
      first_name: '',
      last_name: '',
      email: '',
      mobile_number: '',
      password: '',
      password_confirm: '',
    },
    users: [
      {
        id: uuidv4(),
        level: '',
        level_name: '',
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
      },
    ],
    agent: {
      first_name: '',
      last_name: '',
      mobile_number: '',
      email: '',
    },
    task: {
      name: '',
      forms: [
        {
          id: uuidv4(),
          description: '',
          type: '',
          name: '',
          data: [{id: uuidv4(), name: ''}],
        },
      ],
    },
    errors: {
      admin_data: {},
      users: {},
      agent: {},
      task: {},
    },
  }

  export const actionTypes = {
    SetProfile: '[SetProfile] Action',
    SetStep: '[SetStep] Action',
    SetLanguage: '[SetLanguage] Action',
    SetAdminData: '[SetAdminData] Action',
    SetUsers: '[SetUsers] Action',
    SetAgent: '[SetAgent] Action',
    SetTask: '[SetTask] Action',
    SetEmpty: '[SetEmpty] Action',
    SetError: '[SetError] Action',
  }

  export const reducer = () =>
    persistReducer<IOnboardingState, ActionWithPayload<IOnboardingState>>(
      {
        storage,
        key: 'onboarding',
        whitelist: [
          'profile',
          'step',
          'language',
          'admin_data',
          'users',
          'agent',
          'task',
          'errors',
        ],
      },
      (state: IOnboardingState = initialOnboardingState, action = {type: ''}) => {
        switch (action.type) {
          case actionTypes.SetProfile: {
            const profile = action.payload?.profile
            return {...state, profile}
          }

          case actionTypes.SetStep: {
            const step = action.payload?.step
            return {...state, step}
          }

          case actionTypes.SetLanguage: {
            const language = action.payload?.language
            return {...state, language}
          }

          case actionTypes.SetAdminData: {
            const admin_data = action.payload?.admin_data
            return {...state, admin_data}
          }

          case actionTypes.SetUsers: {
            const users = action.payload?.users
            return {...state, users}
          }

          case actionTypes.SetAgent: {
            const agent = action.payload?.agent
            return {...state, agent}
          }

          case actionTypes.SetTask: {
            const task = action.payload?.task
            return {...state, task}
          }

          case actionTypes.SetEmpty: {
            return {
              ...state,
              profile: {
                company_name: '',
                email: '',
                level: undefined,
              },
              admin_data: {
                first_name: '',
                last_name: '',
                email: '',
                mobile_number: '',
                password: '',
                password_confirm: '',
              },
              users: [
                {
                  id: uuidv4(),
                  level: '',
                  level_name: '',
                  first_name: '',
                  last_name: '',
                  email: '',
                  mobile_number: '',
                },
              ],
              agent: {
                first_name: '',
                last_name: '',
                mobile_number: '',
                email: '',
              },
              task: {
                name: '',
                forms: [
                  {
                    id: uuidv4(),
                    description: '',
                    type: '',
                    name: '',
                    data: [{id: uuidv4(), name: ''}],
                  },
                ],
              },
              errors: {
                admin_data: {},
                users: {},
                agent: {},
                task: {},
              },
            }
          }

          case actionTypes.SetError: {
            const errors = action.payload?.errors
            return {...state, errors}
          }

          default:
            return state
        }
      }
    )

  export const actions = {
    setProfile: (profile: TProfileState) => ({
      type: actionTypes.SetProfile,
      payload: {profile},
    }),

    setStep: (step: number) => ({
      type: actionTypes.SetStep,
      payload: {step},
    }),

    setLanguage: (language: TLanguageState) => ({
      type: actionTypes.SetLanguage,
      payload: {language},
    }),

    setAdminData: (admin_data: TAdminDataState) => ({
      type: actionTypes.SetAdminData,
      payload: {admin_data},
    }),

    setUsers: (users: TUserState[]) => ({
      type: actionTypes.SetUsers,
      payload: {users},
    }),

    setAgent: (agent: TAgentState) => ({
      type: actionTypes.SetAgent,
      payload: {agent},
    }),

    setTask: (task: TTaskState) => ({
      type: actionTypes.SetTask,
      payload: {task},
    }),

    setEmpty: () => ({
      type: actionTypes.SetEmpty,
    }),

    setErrors: (errors: TErrorsState) => ({
      type: actionTypes.SetError,
      payload: {errors},
    }),
  }
}

export default OnboardingRedux
