import {Order} from 'src/app/models'

export enum EOrganizationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export interface IOrganization {
  id: string
  company_id: string
  company_name: string
  account_quota: number
  total_accounts: number
  pic_email: string
  pic_name: string
  status: keyof typeof EOrganizationStatus
}

export interface IOrganizationDetail {
  id: string
  company_id: string
  company_name: string
  pic_email: string
  first_name: string
  last_name: string
  company_description: string
  internal_notes: string
  logo?: string
  account_quota: number
  total_accounts: number
  status: keyof typeof EOrganizationStatus
}

export interface IOrganizationQuery {
  search?: string
  page?: number
  size?: number
  sort?: string
  order?: Order
  status?: keyof typeof EOrganizationStatus
}
