import {FC, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {useMasterLayout} from 'src/app/context/MasterContext'
import {useClickOutside} from 'src/app/hooks/click-outside.hook'
import {IProfile} from 'src/app/models'
import ProfileScreens from 'src/app/modules/profile/routes/Screens'
import {logoutUser} from 'src/app/services'
import AuthRedux from 'src/app/store/Auth/AuthRedux'
import {RootState} from 'src/app/store/RootReducer'
import {cn} from 'src/app/utils/cn.utils'
import {Card} from '../../Libs'
import GIcon from '../../Libs/Icon/GIcon'
import {KTSVG} from '../../Libs/Icon/KTSVG'
import AlertModal from '../../Widgets/ModalCollection/AlertModal'
import {DefaultTitle} from './DefaultTitle'

const HeaderWrapper: FC = () => {
  const {minimize, setMinimize} = useMasterLayout()
  const user = useSelector<RootState>(({auth}) => auth?.user, shallowEqual) as IProfile
  const dispatch = useDispatch()
  const [clickOutsideRef, isShow, setIsShow] = useClickOutside(false)
  const [logoutModal, setLogoutModal] = useState<boolean>(false)

  const handleLogout = async () => {
    await logoutUser()
    setIsShow(false)
    dispatch(AuthRedux.actions.logout())
  }

  const getFirstAndLastInitials = (name?: string) => {
    if (!name) return ''
    const words = name.split(' ')
    if (words.length === 1) return words[0].charAt(0).toUpperCase()
    return (words[0].charAt(0) + words[words.length - 1].charAt(0)).toUpperCase()
  }

  return (
    <>
      <div
        className={cn(
          'fixed top-0 right-0 z-20 h-[55px] p-0 shadow-none bg-white flex items-stretch transition-all duration-300 border border-neutral-100',
          'lg:h-[80px]',
          {'left-0 lg:left-[264px]': !minimize},
          {'left-0 lg:left-[72px]': minimize}
        )}
      >
        <div className={cn('px-8 flex items-stretch justify-between w-full mx-auto')}>
          <div className='flex items-center mr-1 lg:hidden'>
            <div
              onClick={() => setMinimize(!minimize)}
              className='inline-flex items-center justify-center w-10 h-10 px-4 border-0 shadow-none outline-none'
            >
              <KTSVG path='/media/icons/IconBurger.svg' className='text-[#212529]' />
            </div>
          </div>

          <div className={cn('hidden items-stretch justify-between', 'lg:grow lg:flex')}>
            <div className='flex items-center'>
              <DefaultTitle />
            </div>
          </div>

          <div className='flex items-stretch'>
            <div className='flex items-center justify-center'>
              {user?.company?.logo && (
                <>
                  <div>
                    <img
                      src={user?.company?.logo}
                      alt='logo-company'
                      className='max-w-[80px] max-h-[45px]'
                    />
                  </div>
                  <div className='mx-6'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='1'
                      height='50'
                      viewBox='0 0 1 50'
                      fill='none'
                    >
                      <path
                        d='M0.5 0.5L0.499998 49.5'
                        stroke='url(#paint0_linear_22630_327049)'
                        strokeLinecap='round'
                      />
                      <defs>
                        <linearGradient
                          id='paint0_linear_22630_327049'
                          x1='-0.5'
                          y1='0.5'
                          x2='-0.500002'
                          y2='49.5'
                          gradientUnits='userSpaceOnUse'
                        >
                          <stop stopColor='#F3F5F5' stopOpacity='0.5' />
                          <stop offset='0.495' stopColor='#CFD7DB' />
                          <stop offset='1' stopColor='#F3F5F5' stopOpacity='0.5' />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </>
              )}
              {/* <div className='flex items-center justify-center w-10 h-10 mr-10'>
                <GIcon icon='IconBell' className='w-6 h-6 cursor-pointer text-neutral-500' />
              </div> */}
              <div className='relative' ref={clickOutsideRef}>
                <div
                  onClick={() => setIsShow(!isShow)}
                  className='flex items-center cursor-pointer select-none'
                >
                  <div className='text-end'>
                    <div className='font-medium text-fs-9 text-neutral-800'>Hi, {user?.name}</div>
                    <div className='text-fs-10'>
                      {user?.level?.type} - {user?.level?.name}
                    </div>
                  </div>
                  <div className='flex items-center justify-center ml-4'>
                    <div className='flex items-center justify-center w-10 h-10 font-bold tracking-tighter rounded-full bg-primary-300/20 text-primary-500 '>
                      {getFirstAndLastInitials(user?.name)}
                    </div>
                  </div>
                </div>
                {isShow && (
                  <Card.Root className='absolute right-0 top-12 w-auto w-max-[320px] min-w-[280px] rounded-t-lg rounded-b-xl animate-opacity-to-full border border-neutral-200 overflow-hidden'>
                    <div className='p-4 text-fs-9 text-neutral-800'>
                      {/* <div className='font-medium'>You are member of</div> */}
                      <div className='grid grid-cols-[16px_1fr] align-middle'>
                        <div className='mt-2 font-medium whitespace-nowrap'>
                          {user?.company?.name}{' '}
                          <span className='text-neutral-500 text-fs-10'>
                            - ID: {user?.company?.code}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className='mt-3 border rounded-xl bg-neutral-50 border-neutral-200'>
                      <div className='text-fs-9'>
                        <Link to={ProfileScreens.CHANGE_PROFILE.PATH}>
                          <div
                            onClick={() => setIsShow(false)}
                            className='py-[10px] px-4 hover:bg-neutral-100 text-neutral-700 cursor-pointer flex items-center'
                          >
                            <GIcon icon='IconUser' className='mr-2 text-neutral-500' />
                            Profile Saya
                          </div>
                        </Link>

                        <Link to={ProfileScreens.CHANGE_PASSWORD.PATH}>
                          <div
                            onClick={() => setIsShow(false)}
                            className='py-[10px] px-4 hover:bg-neutral-100 text-neutral-700 cursor-pointer flex items-center'
                          >
                            <GIcon icon='IconKey2' className='mr-2 text-neutral-500' />
                            Ubah Password
                          </div>
                        </Link>

                        <div
                          onClick={() => {
                            setLogoutModal(true)
                          }}
                          className='border-t border-neutral-200 py-[10px] px-4 hover:bg-neutral-100 text-danger-600 cursor-pointer flex items-center'
                        >
                          <GIcon icon='IconSignOut' className='mr-2 text-danger-600' />
                          Keluar
                        </div>
                      </div>
                    </div>
                  </Card.Root>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AlertModal
        show={logoutModal}
        setShow={setLogoutModal}
        header={'Keluar dari akun'}
        body={'Apakah Anda yakin ingin keluar dari akun? '}
        confirmMessage={'Keluar'}
        onSubmit={handleLogout}
        modalClassname={'h-[240px]'}
      />
    </>
  )
}

export default HeaderWrapper
