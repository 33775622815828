import {FC, useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import OnboardingRedux from 'src/app/store/Onboarding/OnboardingRedux'
import {RootState} from 'src/app/store/RootReducer'
import SuccessSection from '../components/SuccessSection/SuccessSection'

const OnboardingSuccessPage: FC = () => {
  const dispatch = useDispatch()

  const onboardingRedux = useSelector<RootState>(
    ({onboarding}) => onboarding,
    shallowEqual
  ) as OnboardingRedux.IOnboardingState

  useEffect(() => {
    dispatch(OnboardingRedux.actions.setStep(1))
    dispatch(OnboardingRedux.actions.setEmpty())
    //eslint-disable-next-line
  }, [])

  return (
    <div className='w-full min-h-screen' data-testid='onboarding-success'>
      <SuccessSection state={onboardingRedux} />
    </div>
  )
}

export default OnboardingSuccessPage
